html {
  font-size: $font-size-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-desktop;
  }
}

body {
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  min-height: 100vh;
  font-weight: $weight-normal;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcf2ed;
}